import './App.css';

import Nav from './components/Nav'
import TopBody from './components/TopBody';
import Card from './components/Card';
import BottomBody from './components/BottomBody';
import Checklist from './components/Checklist';
import Why from './components/Why';
import Footer from './components/Footer';

export default function Home() {

  return ( 
    <div className="home">
        <Nav/>
        <TopBody/>
        <Card/>
        <BottomBody/>
        <Checklist/>
        <Why/>
        <Footer/>
    </div>
  )
}
