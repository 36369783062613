import "./App.css";
import { Routes, Route } from "react-router-dom";

import Quote from "./components/Quote";
import Comingpage from "./Comingpage";
import Home from "./Home";
import Claims from "./components/Claims";
import Motto from "./components/Motto";
import Rental from "./components/Rental";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/home" element={<Comingpage/>} />
        <Route path="/claims" element={<Claims />} />
        <Route path="/quote" element={<Quote />} />
        <Route path="/MotoMaxx" element={<Motto />} />
        <Route path="/Rental" element={<Rental />} />
      </Routes>
    </div>
  );
}

export default App;
