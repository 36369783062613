import React from "react";

import { useNavigate } from "react-router-dom";
import "./TopBody.css";
import pic1 from "../images/IMG_0814.PNG";

export default function TopBody() {
  const navigate = useNavigate();

  function handleGetQuote() {
    navigate("/quote");
  }

  return (
    <div className="Topbody-container">
      <div className="topbody-left">
        <h1>Simple Insurance, Online Convenience.</h1>
        <p>
          Spring is here to give you the best insurance advice. Understand your
          policy, get answers to your questions and get insurance - all in one
          place.
        </p>
        <button>
          <span className="btn-txt" onClick={handleGetQuote}>
            Get a Quote
          </span>
        </button>
      </div>

      <div className="animation-picture">
        <img src={pic1} alt="Example" />
      </div>
    </div>
  );
}
