import React from 'react'; 
import './Why.css'; 
import {BsCheck} from 'react-icons/bs';
import {RiShieldCheckFill} from 'react-icons/ri';
import {IoIosArrowForward}from 'react-icons/io';


export default function Why () {

  return ( 
    <div className='why-container'>
       <div className='why-left'>
        <h2>Why you should buy from Spring</h2>
        <p>If you’re still reading this, we are pretty sure you already recognize the benefit of using Ditto. However, if you are still not convinced, here’s a few extra points to change your mind</p>
        <p>
          <BsCheck/> Chat Support for additional queries </p>
        <p>
          <BsCheck/> Claim Support for when you have to make a claim </p>
        <p>
          <BsCheck/> Oral Support for when you need reassurance 😜 </p>
        </div>

      <div className='animation-picture-1'>
        <div className='card-why'>
        <div >

          <div className='why-card-words'>
            <div>
              <RiShieldCheckFill 
              className='card-symbol'
                size={'50px'}
                color = '#5dcf6c'
              />
              <h2>Health Insurance</h2>
              <p> Use our intuitive platform to understand, compare & buy comprehensive plan</p>
            </div>
            <IoIosArrowForward className='why-arrow'size={'40px'}/>
          </div>
        </div>
        </div>
      </div>
    </div>
  )
}