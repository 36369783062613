import React from "react";
import "./BottomBody.css";
import { AiOutlineCalendar } from "react-icons/ai";
import { BsCheck } from "react-icons/bs";

export default function BottomBody() {
  return (
    <div className="bottom-container">
      <div className="bottom-words">
        <h3>GOODBYE WASTED TIME</h3>
        <h1>Get a quote now!</h1>

        <div className="floating-container">
          <div className="bottom-text-container">
            <h1>Trustworthy Guidance</h1>
            <p>
              When you get a quote with Spring, you tap into the expertise of
              top insurance advisors.
            </p>

            <div className="text-checklist">
              <div className="checklist-section">
                <div className="text-container">
                  <h4>
                    {" "}
                    <BsCheck /> No Pushy Salesfolk
                  </h4>
                  <p>
                    {" "}
                    Expect genuine conversations, no aggresive sales tactics.
                    Our team is made up of exceptional advisors.
                  </p>
                </div>
                <div>
                  <h4>
                    {" "}
                    <BsCheck /> Zero Spam, Guaranteed
                  </h4>
                  <p>
                    {" "}
                    We won’t surprise you with unwanted calls. Count on the
                    Spring Hotline to be spam-free.
                  </p>
                </div>
              </div>

              <div className="checklist-checklist">
                <div className="checklist-section">
                  <div className="text-container">
                    <h4>
                      {" "}
                      <BsCheck /> 10-Minute Chat
                    </h4>
                    <p>
                      {" "}
                      Quality advice deserves time. You'll have our complete
                      focus to answer any questions you may have.
                    </p>
                  </div>
                  <div>
                    <h4>
                      {" "}
                      <BsCheck /> Absolutely Free
                    </h4>
                    <p>
                      {" "}
                      Our consultations won't cost you a dime, and we won’t
                      pressure you to buy from Spring.
                    </p>
                  </div>
                </div>
              </div>
              <button>
                <AiOutlineCalendar
                  style={{ marginRight: "7px" }}
                  size="17px"
                  color="white"
                />
                <span className="btn-txt">Book a call</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
