import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { RiShieldCheckFill } from "react-icons/ri";
import "./Card.css";

export default function Card() {
  return (
    <div className="cards-container">
      <div className="card-title">
        <h4>WELCOME TO SPRING</h4>
        <h2>What we do</h2>
        <p>
          We've got your back when it comes to picking the right policy for your
          needs. We'll also guide you through the purchase process. And hey, if
          you ever need to use that policy, we'll be there to help you with the
          claims hassle. Think of us as your go-to for all things
          insurance-related.
        </p>
      </div>

      <div className="card-container">
        <div className="card">
          <div className="card-words">
            <RiShieldCheckFill
              className="card-symbol"
              size={"50px"}
              color="#5dcf6c"
            />
            <h2>Vehicle Replacement Cost Insurance</h2>
            <p>
              {" "}
              Vehicle replacement cost insurance guarantees that if your car is
              written off, you'll receive the funds needed to buy a new one,
              keeping you in the driver's seat without worrying about
              depreciation.
            </p>
            <button>
              <AiOutlineCalendar
                style={{ marginRight: "7px" }}
                size="17px"
                color="white"
              />
              <span className="btn-txt">Book a call</span>
            </button>
          </div>
        </div>

        <div className="card">
          <div className="card-words">
            <RiShieldCheckFill
              className="card-symbol"
              size={"50px"}
              color="#5dcf6c"
            />
            <h2>Tenant Insurance</h2>
            <p className="tenant-ins-words">
              {" "}
              Imagine tenant insurance, or renters insurance, as your personal
              item superhero. If your stuff gets nabbed or toasted, it's the
              hero that swoops in to save the day.
            </p>

            {/* <button>
              <span className="btn-txt">Coming Soon!</span>
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
