import "./App.css";

import ComingSoon from "./components/Comingsoon";


export default function Comingpage() {
  return (
    <div className="coming-page">
      <ComingSoon />
    </div>
  );
}
