import React from 'react'; 
import './Footer.css';
// import {AiOutlineCalendar} from 'react-icons/ai'

export default function Footer () {

  return (
    <div className='footer-container'>
      <div className='footer-left'>
        <h1>Spring.</h1>
        <p>With Spring, we help make insurance easy.</p>
        <br/>
        <p>Head Office</p>
        <p>307 - 197 Forester Street</p>
        <p>North Vancouver BC, V7H 0A6</p>
        <br/>
        <p>© All rights reserved by Spring Insurance Inc</p>
        {/* <button>
                <AiOutlineCalendar
                  style={{ marginRight: "7px" }}
                  size="17px"
                  color="white"
                />
                <span className="btn-txt">Book a Call</span>
        </button> */}
        {/* <p>For Insurance Queries:</p>
        <p>help@joinspring.com | 604-48816818</p>
        <p>For Hiring Queries:</p>
        <p>help@joinspring.com | 604-48816818</p> */}
      </div>
      <div className='footer-right'>
        <div className='footer-column'>
          <h2>Products</h2>
          <p>Health Insurance</p>
          <p>Rental Insurance</p>
        </div>
        {/* <div className='footer-column'>
          <h2>Tools</h2>
          <p>Understand your Insurance</p>
          <p>Insurance Checklist</p>
        </div> */}
        {/* <div className='footer-column'>
          <h2>Guides</h2>
          <p>Health Insurance 101</p>
          <p>Term Insurance 101</p>
        </div> */}
        <div className='footer-column'>
          <h2>General</h2>
          <p>Terms and Conditions</p>
          <p>Privacy Policy</p>
          <p>Car Replacement Insurers</p>
          <p>Rental Insurers</p>
          <p>Claims Assistance</p>
        </div>
    
      </div>
    </div>
  )
}