import React from "react";
import "./Quote.css";
import Nav from "./Nav";
import axios from "axios";
import { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { AiOutlineMail } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";

export default function Quote() {
  // setting value state for each input
  const [postalCode, setPostalCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [kilometers, setKilometers] = useState("");
  const [vehicleAge, setVehicleAge] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  function generateYearOptions() {
    const currentYear = new Date().getFullYear();
    const startYear = 1997;
    const yearOptions = [];

    for (let year = currentYear; year >= startYear; year--) {
      yearOptions.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }

    return yearOptions;
  }

  // postalcode formatting
  // const [isValidPostalCode, setIsValidPostalCode] = useState(true);

  // const handlePostalCodeChange = (e) => {
  //   const inputValue = e.target.value;
  //   setPostalCode(inputValue);

  //   // Regular expression to match the format "V1L 1A1"
  //   const postalCodePattern = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;

  //   setIsValidPostalCode(postalCodePattern.test(inputValue));
  // };

  // setting error state for each input
  const [fieldErrors, setFieldErrors] = useState({
    postalCode: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    vehicleType: false,
    purchasePrice: false,
    kilometers: false,
    vehicleAge: false,
  });

  const [errorMessage, setErrorMessage] = useState({
    postalCode: "Please Complete This Required Field",
    firstName: "Please Complete This Required Field",
    lastName: "Please Complete This Required Field",
    email: "Please Complete This Required Field",
    phone: "Please Complete This Required Field",
    vehicleType: "Please Complete This Required Field",
    purchasePrice: "Please Complete This Required Field",
    kilometers: "Please Complete This Required Field",
    vehicleAge: "Please Complete This Required Field",
  });

  // Handle form submission

  const handleSubmit = async (event) => {
    event.preventDefault();

    // handle empty fields
    const newFieldErrors = {};
    const newErrorMessages = {};

    if (postalCode === "") {
      newFieldErrors.postalCode = true;
      newErrorMessages.postalCode = "Please Complete This Required Field";
      console.log(`1`);
    }
    if (firstName === "") {
      newFieldErrors.firstName = true;
      newErrorMessages.firstName = "Please Complete This Required Field";
      console.log(`Please fill in the required fields.2`);
    }
    if (lastName === "") {
      newFieldErrors.lastName = true;
      newErrorMessages.lastName = "Please Complete This Required Field";
      console.log(`Please fill in the required fields.3`);
    }
    if (email === "") {
      newFieldErrors.email = true;
      newErrorMessages.email = "Please Complete This Required Field";
      console.log(`Please fill in the required fields.4`);
    }
    if (phone === "") {
      newFieldErrors.phone = true;
      newErrorMessages.phone = "Please Complete This Required Field";
      console.log(`Please fill in the required fields.5`);
    }
    if (vehicleType === "") {
      newFieldErrors.vehicleType = true;
      newErrorMessages.vehicleType = "Please Complete This Required Field";
      console.log(`Please fill in the required fields.6`);
    }

    if (purchasePrice === "") {
      newFieldErrors.purchasePrice = true;
      newErrorMessages.purchasePrice = "Please Complete This Required Field";
      console.log(`Please fill in the required fields.7`);
    }
    if (kilometers === "") {
      newFieldErrors.kilometers = true;
      newErrorMessages.kilometers = "Please Complete This Required Field";
      console.log(`Please fill in the required fields.8`);
    }
    // if (vehicleMake === "") {
    //   newFieldErrors.vehicleMake = true;
    //   newErrorMessages.vehicleMake = "Please Complete This Required Field";
    // }
    if (vehicleAge === "") {
      newFieldErrors.vehicleAge = true;
      newErrorMessages.vehicleAge = "Please Complete This Required Field";
      console.log(`Please fill in the required fields.9`);
    }

    setFieldErrors(newFieldErrors);
    setErrorMessage(newErrorMessages);

    if (Object.keys(newFieldErrors).length > 0) {
      console.log(`Please fill in the required fields.`);
      return;
    }

    const formData = {
      postal: postalCode,
      firstName,
      lastName,
      emailAddress: email,
      phoneNumber: phone,
      vehicleType,
      purchasePrice,
      kilometers,
      vehicleAge,
    };

    try {
      const instance = axios.create({
        baseURL: "https://spring-insurance-3521a0b6f890.herokuapp.com/", // Adjust the URL as needed
      });

      await instance.post("/api/quotes", formData);
      // Handle success, maybe show a success message
      console.log("able to reach");
      setShowPopup(true);
    } catch (error) {
      console.log("ERROR MESSAGE AXIOS", error);
      // Handle error, maybe show an error message
    }
  };

  // clear form when submitted
  const resetForm = () => {
    setPostalCode("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setVehicleType("");
    setPurchasePrice("");
    setKilometers("");
    setVehicleAge("");
    setFieldErrors({
      postalCode: false,
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      vehicleType: false,
      purchasePrice: false,
      kilometers: false,
      vehicleAge: false,
    });
    setErrorMessage({
      postalCode: "Please Complete This Required Field",
      firstName: "Please Complete This Required Field",
      lastName: "Please Complete This Required Field",
      email: "Please Complete This Required Field",
      phone: "Please Complete This Required Field",
      vehicleType: "Please Complete This Required Field",
      purchasePrice: "Please Complete This Required Field",
      kilometers: "Please Complete This Required Field",
      vehicleAge: "Please Complete This Required Field",
    });
  };

  return (
    <div className="quote-container">
      <Nav />

      <div className="under-nav">
        <div className="claim-form-container">
          <h2>Get Your Free Insurance Quote</h2>
          <form className="claim-form" onSubmit={handleSubmit}>
            <label className="claim-label">1. Tell Us About Yourself:</label>
            <input
              placeholder="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
                setFieldErrors({ ...fieldErrors, firstName: false });
              }}
              className={fieldErrors.firstName ? "error" : ""}
            ></input>
            {fieldErrors.firstName && (
              <p className="error-message">{errorMessage.firstName}</p>
            )}

            <input
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
                setFieldErrors({ ...fieldErrors, lastName: false });
              }}
              className={fieldErrors.lastName ? "error" : ""}
            ></input>
            {fieldErrors.lastName && (
              <p className="error-message">{errorMessage.lastName}</p>
            )}

            <input
              placeholder="Email Address"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setFieldErrors({ ...fieldErrors, email: false });
              }}
              className={fieldErrors.email ? "error" : ""}
            ></input>
            {fieldErrors.email && (
              <p className="error-message">{errorMessage.email}</p>
            )}

            <input
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                setFieldErrors({ ...fieldErrors, phone: false });
              }}
              className={fieldErrors.phone ? "error" : ""}
            ></input>
            {fieldErrors.phone && (
              <p className="error-message">{errorMessage.phone}</p>
            )}

            <label className="claim-label">2. Vehicle Purchase Price: </label>
            <input
              placeholder="$10,000"
              value={purchasePrice}
              onChange={(e) => {
                setPurchasePrice(e.target.value);
                setFieldErrors({ ...fieldErrors, purchasePrice: false });
              }}
              className={fieldErrors.purchasePrice ? "error" : ""}
            ></input>
            {fieldErrors.purchasePrice && (
              <p className="error-message">{errorMessage.purchasePrice}</p>
            )}

            <label className="claim-label">
              3. Tell Us About your Vehicle:
            </label>
            <input
              placeholder="Make and Model"
              value={vehicleType}
              onChange={(e) => {
                setVehicleType(e.target.value);
                setFieldErrors({ ...fieldErrors, vehicleType: false });
              }}
              className={fieldErrors.vehicleType ? "error" : ""}
            ></input>
            {fieldErrors.vehicleType && (
              <p className="error-message">{errorMessage.vehicleType}</p>
            )}

            <label className="claim-label">4. Year of Vehicle: </label>
            <select
              value={vehicleAge}
              onChange={(e) => {
                setVehicleAge(e.target.value);
                setFieldErrors({ ...fieldErrors, vehicleAge: false });
              }}
              className={fieldErrors.vehicleAge ? "error" : ""}
            >
              <option> -Select- </option>
              {generateYearOptions()}
            </select>

            {fieldErrors.vehicleAge && (
              <p className="error-message">{errorMessage.vehicleAge}</p>
            )}
            <label className="claim-label">
              5. Approximate Current Vehicle Kilometers:{" "}
            </label>

            <input
              value={kilometers}
              placeholder="8,000 KM"
              onChange={(e) => {
                setKilometers(e.target.value);
                setFieldErrors({ ...fieldErrors, kilometers: false });
              }}
              className={fieldErrors.kilometers ? "error" : ""}
            ></input>

            {fieldErrors.kilometers && (
              <p className="error-message">{errorMessage.kilometers}</p>
            )}

            <label className="claim-label">

              6. Enter the VIN of your Vehicle:
            </label>
            <input
              placeholder="17 characters (digits and capital letters)"
              value={postalCode}
              onChange={(e) => {
                setPostalCode(e.target.value);
                setFieldErrors({ ...fieldErrors, postalCode: false });
              }}
              className={fieldErrors.postalCode ? "error" : ""}
            ></input>
            {fieldErrors.postalCode && (
              <p className="error-message">{errorMessage.postalCode}</p>
            )}

            <button type="submit">
              <p className="claim-btn-word"> Submit for a Quote </p>
            </button>
          </form>
          {showPopup && (
            <div className="popup">
              <MdOutlineCancel
                className="close-btn"
                onClick={() => {
                  setShowPopup(false);
                  resetForm();
                }}
              />
              <div className="pop-up-word">
                <p>Thank You for your submissions. </p>
                <p> Your quote would be sent to your email shortly.</p>
              </div>
            </div>
          )}
        </div>

        <div className="quote-right">
          <h4 className="claim-contact-title">
            {" "}
            <AiOutlineMail /> Chat with Us{" "}
          </h4>
          <p>Our friendly team is here to help.</p>
          <p> info@springinsurance.ca</p>
          {/* <h4 className='c-t'> <FiPhoneCall/> Call Us </h4>
            <p>+1 (604) 888-8888</p> */}
          <h4 className="c-t">
            {" "}
            <BiTimeFive /> Hours of Operations
          </h4>
          <p> Monday to Friday Pacific Standard Time</p>
          <p> 8:00 AM - 5:00 PM</p>
        </div>
      </div>
    </div>
  );
}
