import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SlArrowDown } from "react-icons/sl";
import { FaBars } from "react-icons/fa";
import "./Nav.css";
import logo from "../images/logo.png";


export default function Nav() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMobileMenu = () => {
    console.log("Toggling mobile menu");
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    console.log("toggle closed")
    setIsMobileMenuOpen(false);

  };


  const navigate = useNavigate();
  
  function handleHomeClick() {
    navigate("/");
    closeMobileMenu();
  }
  function handleNewPageClick() {
    navigate("/claims");
    closeMobileMenu();
  }

  function handleBuyClick() {
    navigate("/quote");
    closeMobileMenu();
  }

  function handleMottoClick() {
    navigate("/motomaxx");
    closeMobileMenu();
  }
  function handleRentalClick() {
    navigate("/rental"); 
    closeMobileMenu();
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setIsMobileScreen(true);
      } else {
        setIsMobileScreen(false);
        closeMobileMenu();
      }
    };

    // Call the handleResize function initially and add event listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div className="nav-container">
      
    <div onClick={handleHomeClick} className="logo">        
    <img src={logo} alt="Example" />
</div>
    {isMobileScreen && (
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
        <FaBars className="burger"/>
      </div>
    )}
    {isMobileMenuOpen && (
       <ul className="nav-list">
       <li onClick={toggleDropdown} className="dropdown-trigger">
         <span className="nav-item-text">Products</span>
         <div className="arrow">
           <SlArrowDown
             className={`arrow-down ${isDropdownOpen ? "open" : ""}`}
             color="grey"
             size={10}
           />
         </div>
         {isDropdownOpen && (
           <div className="dropdown-content">
             <div className="dropdown-option" onClick={handleMottoClick}>Motomaxx</div>
             <div className="dropdown-option" onClick={handleRentalClick}>Rental</div>
           </div>
         )}
       </li>
 
       <li onClick={handleNewPageClick}>Submit Claim</li>
       <li>Support</li>
       <li className="buy-ins">
         <span className="buy-word" onClick={handleBuyClick}>Buy Insurance</span>
       </li>
     </ul>
 
    )}
    {!isMobileScreen && (
      <ul className="nav-list">
      <li onClick={toggleDropdown} className="dropdown-trigger">
        <span className="nav-item-text">Products</span>
        <div className="arrow">
          <SlArrowDown
            className={`arrow-down ${isDropdownOpen ? "open" : ""}`}
            color="grey"
            size={10}
          />
        </div>
        {isDropdownOpen && (
          <div className="dropdown-content">
            <div className="dropdown-option" onClick={handleMottoClick}>Motomaxx</div>
            <div className="dropdown-option" onClick={handleRentalClick}>Rental</div>
          </div>
        )}
      </li>

      <li onClick={handleNewPageClick}>Submit Claim</li>
      <li>Support</li>
      <li className="buy-ins">
        <span className="buy-word" onClick={handleBuyClick}>Buy Insurance</span>
      </li>
    </ul>
    )}
     


  </div>
  );
}
