import React from "react";
import Nav from "./Nav";
import "./Rental.css";

export default function Rental() {
  return (
    <div className="rental-cont">
      <Nav />

      <div className="rental-body">
        <h1>Coming Soon ...</h1>
      </div>
    </div>
  );
}
