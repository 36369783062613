import React from 'react'; 
import Nav from './Nav';
import './Claims.css'
import { useState } from "react";
import {AiOutlineMail} from 'react-icons/ai';
import {BiTimeFive} from 'react-icons/bi';



export default function Claims() {

  // setting state for clearing form placeholder when typing 
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [policy, setPolicy] = useState("");
  const [vehicleType, setVehicleType] = useState("");


  return (
    <div className='claim-container'> 
      <div className='claim-nav'>
        <Nav />
      </div>

        <div className='under-nav'>
          
          <div className='contact-left'> 

            <h4 className='claim-contact-title'> <AiOutlineMail /> Chat with Us </h4>
            <p>Our friendly team is here to help.</p>
            <p> info@springinsurance.ca</p>

            {/* <h4 className='c-t'> <FiPhoneCall/> Call Us </h4>
            <p>+1 (604) 888-8888</p> */}
            <h4 className='c-t'> <BiTimeFive/> Hours of Operations</h4>
            <p> Monday to Friday Pacific Standard Time</p>
            <p> 8:00 AM - 5:00 PM</p>
           
          </div>


          <div className='claim-form-container'>
            <h2> 
              Submit Your Claim Below
            </h2>
            <form className='claim-form'>
              <input 
              placeholder={'First Name'}
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              ></input>
              <input 
              placeholder={'Last Name'}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              ></input>
              <input 
              placeholder={'Email'}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              ></input>
              <input 
              placeholder={'Phone Number'}
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              ></input>
              <input 
              placeholder={'Policy Number '}
              value={policy}
              onChange={(e) => {
                setPolicy(e.target.value);
              }}
              ></input>
              <input 
              placeholder={'Vehicle Make, Model, Year'}
              value={vehicleType}
              onChange={(e) => {
                setVehicleType(e.target.value);
              }}
              ></input>
              <button> 
                <p className='claim-btn-word'> Submit Claim </p>
              </button>
            </form>
          </div>
        </div>
    </div>
  )
}