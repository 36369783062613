import React from "react";
import Nav from "./Nav";
import "./Motto.css";
import bus from "../images/bus.PNG";
import { AiFillCar } from "react-icons/ai";
import { useState } from "react";
import { SlArrowDown } from "react-icons/sl";

export default function Motto() {
  const faqData = [
    {
      question: "O.E.M Parts",
      answer: "A New Vehicle policy covers you for OEM (Original Equipment from Manufacture) Parts. Meaning, if you have a partial loss claim in the first 3 years, the only parts that can be used are brand new parts - no aftermarket pieces.",
    },
    {
      question: "Deductible Reimbursements",
      answer: "Motomaxx offers UNLIMITED deductible reimbursements. Therefore, in the event of a total loss, up to $500 of your deductible will be covered. And, in the event of a partial loss (ex. windshield, fender bender, etc.), up to $500 of your deductible will be covered. With no limit in frequency of claims!",
    },
    {
      question: "Extended Rental Coverage",
      answer: "In the event of Total Loss or Partial Loss during which the Insured is deprived of their vehicle for more than 3 days, the Insurer will pay up to the amount of $100/day for a maximum of $1000. Insured is required to have underlying rental vehicle coverage for this coverage to apply.",
    },
    // Add more FAQ items here
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="motto-cont">
      <Nav />

      <div className="motto-word-container">
        <div className="moto-title">
          <h1>MotoMaxx Insurance</h1>
        </div>

        <div className="m-body-div">
          <div className="topbody-left-m">
            <h1 className="motto-h1">
              Insurance made <br />
              <span> Easy</span>
            </h1>
            <div className="m-grid">
              <div className="grid-left">
                <h3>
                  {" "}
                  <AiFillCar className="car-filled" /> New Vehicle
                </h3>
                <div className="grid-words">
                  <p>Motomaxx policy: </p>
                  <p>
                    will pay you the difference between that depreciated value
                    and the purchase price of the newest year/make/model (or
                    similar make and model).
                  </p>
                </div>
              </div>

              <div className="grid-right">
                <h3>
                  {" "}
                  <AiFillCar className="car-filled" /> Used Vehicle
                </h3>
                <div className="grid-words">
                  <p>Motomaxx policy: </p>
                  <p>
                    Used vehicle adds 5% appreciation to the purchase price
                    /year, each year you have owned the insured vehicle
                  </p>
                  <p>
                    Used Vehicle policy is a described vehicle within 10 model
                    years and 25,001 km's or more.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="motto-right">
            <p>
              {" "}
              The underlying insurer will pay you the depreciated value for your
              vehicle. Your Motomaxx policy will pay you the difference between
              that depreciated value and the purchase price.
            </p>
            <br />

            <div className="motto-picture">
              <img src={bus} alt="Example" />
            </div>
          </div>
        </div>

        <div className="sec-bod-div">
          <h2> Adding Coverage's</h2>
          <>
            Motomaxx limits you from financial loss. Whether you’re financing,
            leasing, or outright own your vehicle - you’ll be at peace knowing
            your investment is fully covered. You’ll receive additional benefits
            within your term. Some of the key benefits are:
          </>


          <div className="faq-container">
            {faqData.map((item, index) => (
              <div className="faq-item" key={index}>
                <div
                  className={`question ${
                    activeIndex === index ? "active" : ""
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  {item.question}
                  <div className="arrow">
                   <SlArrowDown
                      className={`arrow-down ${
                        activeIndex === index ? "open" : ""
                      }`}
                      color="grey"
                      size={10}
                    />
                  </div>
                </div>
                {activeIndex === index && (
                  <div className="answer">{item.answer}</div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  );
}
