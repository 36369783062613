import React from "react";
import "./Comingsoon.css";
import mascotImage from "../images/IMG_0814.PNG";

export default function ComingSoon() {
  return (
    <div className="bouncing-mascot-container">
    <img src={mascotImage} alt="Mascot" className="bouncing-mascot" />
    <h2>Spring Insurance Inc</h2>
    <div className="coming-soon">Coming Soon ... </div>
    
  </div>
  );
}
