import React from 'react'; 
import './Checklist.css'
import {BsCardChecklist} from 'react-icons/bs';
import {RiNumber1, RiNumber2, RiNumber3} from 'react-icons/ri';
import car from '../images/car.PNG'



export default function Checklist () {

  return (
    <div className='checklist-container'>
        <div className='list-topbody-left'>
        <h3>Insurance Checklist</h3>
        <p>We know how difficult it can be to navigate through hundreds of policies. So we've designed this handy checklist to make sure you know exactly what to look for in a good policy.</p>
        <div className='checklist'>
          <p>The must have's are</p>
          <p className='checklist-item'>
            <RiNumber1/> Dont split the bill</p>
          <p className='checklist-item-1'>
            <RiNumber2/>  Check the restrictions on room</p>
          <p className='checklist-item-2'>
            <RiNumber3/>  Check for disease wise sub-limits</p>
        </div>
        <button> 
          <BsCardChecklist
            style={{marginRight: '7px'}}
            color= 'white'
          />
          <span className='btn-txt'>See the checklist</span>
        </button>
      </div>
      <div className='animation-picture'>
      <img src={car} alt="Example" />
      </div>
    </div>
  )
}